import React, {useEffect, useState} from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/layout'
import loadable from '@loadable/component'
import {Basic, Facebook, Twitter} from '../components/SEO'
import {List, WindowScroller} from 'react-virtualized'
import 'react-virtualized/styles.css'
import useWindowDimensions from '../hooks/useWindowDimensions'
import CategoryTitleBordered from '../components/helpers/categoryTitleBordered'
import {track} from '@cg-squad/ui-components'
import mixpanel from '../constants/mixpanel'
import {adDisplayDelay, delayMultiplicationFactor, setStreamData} from '../utils/articleUtil'
import {subCategoryPageAds} from '../utils/adUnits'
import {isMobile} from 'react-device-detect'
import {metadata} from '../../config'

const PopularPosts = loadable(() => import("../components/popularPosts"));
const ArticlePreview = loadable(() => import("./articlePreview"));

const SubCategoryPage = ({data, pageContext, location}) => {
  if(data.pillarArticles.nodes[0]) {
    if(data.allDatoCmsArticle.nodes) {
        data.allDatoCmsArticle.nodes = data.allDatoCmsArticle.nodes.filter(node => {
            if(node.id==data.pillarArticles.nodes[0].id) {
                data.allDatoCmsArticle.totalCount = data.allDatoCmsArticle.totalCount - 1;
            } else {
                return node;
            }
        });
    }
  }

  const seo = {
    title: pageContext.title,
    description: `Discover all of our ${pageContext.title} articles, written by our knowledgeable team of writers.`
  }
  const url = `${metadata.url}/${pageContext.categorySlug}/${pageContext.slug}`;

    const [rowHeight, setRowHeight] = useState(279);
    const [articleHeight, setArticleHeight] = useState(250);
    const {width} = useWindowDimensions();


    const calculateHeights = () => {
        if (width <= 375) {
            setRowHeight(249);
            setArticleHeight(229)
        } else if (width <= 560) {
            setRowHeight(229);
            setArticleHeight(198)
        }
    }

    useEffect(() => {
        calculateHeights();
        setStreamData({
            category: undefined,
            subCategory: pageContext.title,
            article: undefined,
            author: undefined
        })
        track(mixpanel.MIXPANEL_PAGE_VIEW.SUB_CATEGORY);
      const timeout = setTimeout(subCategoryPageAds, adDisplayDelay() * delayMultiplicationFactor(isMobile), [
        {key: 'category', value: pageContext.categorySlug},
        {key: 'subcategory', value: pageContext.slug}
      ]);
      return () => {
        clearTimeout(timeout);
      };
    }, []);

    useEffect(() => {
        calculateHeights();
    }, [width]);

    function rowRenderer({
                             index, // Index of row
                             isScrolling, // The List is currently being scrolled
                             isVisible, // This row is visible within the List (eg it is not an overscanned row)
                             key, // Unique key within array of rendered rows
                             parent, // Reference to the parent List (instance)
                             style, // Style object to be applied to row (to position it);
                             // This must be passed through to the rendered row element.
                         }) {

        return (
            <div key={key} style={style}>
                {isScrolling && !isVisible ?
                    <div style={{minHeight: articleHeight, width: "100%"}} className={"article-placeholder"}/> :
                    <ArticlePreview style={{minHeight: articleHeight}} data={data.allDatoCmsArticle.nodes[index]}
                                    className={"border-b-default border-denim"}
                                    imageClass="mb-0 w-1/3 lg:mr-4 float-right ml-2 lg:ml-0 lg:float-left mt-8 md:mt-0 max-w-320"
                                    headingClass="font-montserrat font-bold leading-snug mb-1 md:mb-4 text-lg line-clamp l-c-5"
                                    textClass={"line-clamp l-c-3 mb-0"}
                                    noFlag={true}/>}
            </div>
        );
    }

    return (
        <Layout path={location.pathname}>
          <Basic seo={seo}/>
          <Twitter seo={seo} url={url}/>
          <Facebook seo={seo} url={url}/>
            <div className="wrapper" data-datocms-noindex>
                <div className={"w-full lg:flex font-arial"}>
                    <div className={"flex-grow-3"}>
                        <CategoryTitleBordered title={pageContext.title}/>
                            {data.pillarArticles.nodes[0] && <ArticlePreview style={{minHeight: articleHeight}} data={data.pillarArticles.nodes[0]}
                                            className={"border-b-default border-denim mb-8"}
                                            imageClass="mb-0 w-1/3 lg:mr-4 float-right ml-2 lg:ml-0 lg:float-left mt-8 md:mt-0 max-w-320"
                                            headingClass="font-montserrat font-bold leading-snug mb-1 md:mb-4 text-lg"
                                            textClass={"line-clamp l-c-3 mb-0"}
                                            noFlag={true}/>}
                      <div className="ad-container mb-2" data-datocms-noindex>
                        <div className="advertisement-text">Advertisement</div>
                        <div id="at_subcategory_incontent1"/>
                      </div>
                            <WindowScroller>
                                {({height, isScrolling, onChildScroll, scrollTop, width}) => (
                                    <List
                                        autoHeight
                                        height={height}
                                        isScrolling={isScrolling}
                                        onScroll={onChildScroll}
                                        rowCount={data.allDatoCmsArticle.totalCount}
                                        rowRenderer={rowRenderer}
                                        scrollTop={scrollTop}
                                        rowHeight={rowHeight}
                                        width={width}
                                        style={{width: "unset !important"}}
                                    />
                                )}
                            </WindowScroller>
                    </div>
                    <PopularPosts affiliateCategory={pageContext.slug} articles={data.relatedArticles} />
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
    query ArticlesSubCategoryWise($slug: String, $domain: String) {
        allDatoCmsArticle(filter: {category: {elemMatch: {slug: {eq: $slug}}}, website: {elemMatch: {name: {eq: "at"}}}, domains: {elemMatch: {name: {eq: $domain}}}}, sort: {order: DESC, fields: publishedAt}) {
            nodes {
                ...ArticleFieldsPlugin
            }
            totalCount
        }
        pillarArticles: allDatoCmsArticle(filter: {category: {elemMatch: {slug: {eq: $slug}}}, website: {elemMatch: {name: {eq: "at"}}}, isCategoryPinned: {eq: true}, domains: {elemMatch: {name: {eq: $domain}}}}, sort: {order: DESC, fields: publishedAt}, limit: 1) {
            nodes {
                ...ArticleFieldsPlugin
            }
        }
        relatedArticles: allDatoCmsArticle(
            filter: {category: {elemMatch: {originalId: {nin: ["39161838"]}}}, website: {elemMatch: {name: {eq: "at"}}}, domains: {elemMatch: {name: {eq: $domain}}}}
            limit: 5
            skip: 1
        ) {
            nodes {
                ...ArticleFieldsPluginWithPortraitImage
            }
        }
    }
`
export default SubCategoryPage
